<template id="statistiche">
  <b-container fluid>
    <div class="content">
      <b-row class="mt-2">
        <b-col cols="2">
          <b-input-group prepend="From">
            <b-form-datepicker
              v-model="filters.from"
              :dark="true"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-input-group>
        </b-col>
        <b-col cols="2">
          <b-input-group prepend="To">
            <b-form-datepicker
              v-model="filters.to"
              :dark="true"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-input-group>
        </b-col>
      </b-row>
      <div class="row mt-3">
        <div class="col-3" style="height: 70vh; overflow: auto">
          <div class="row">
            <div class="col-1 mr-2">
              <flag :country="paese" :size="32"></flag>
            </div>
            <div class="col">
              <span class="m-1" style="font-size: 1.25rem">{{ title }}</span>
            </div>
          </div>
          <div v-if="loading" align="center" style="width: 100%">
            <img :src="loadingImg" style="width: 150px" />
          </div>
          <div class="row my-2 border-bottom" v-if="stats.squadre && !loading">
            <h5>Squadre osservate: {{ stats.squadre.length }}</h5>
          </div>
          <div
            class="row my-3 pb-2 align-items-center border-bottom"
            v-for="squadra in stats.squadre"
            :key="squadra.id"
          >
            <div class="col-2">
              <img :src="squadra.logo_url" height="50" />
            </div>
            <div class="col">
              <span style="font-size: 1.2rem">{{ squadra.name }}</span
              ><br />
              <span>partite: {{ squadra.partite_count }}</span
              ><br />
              <span>valutazioni: {{ squadra.count_val_gio }}</span>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div id="chartdiv" style="width: 100%; height: 500px"></div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow";
import am4geodata_ukCountriesLow from "@amcharts/amcharts4-geodata/ukCountriesLow";
import moment from "moment";
import Flag from "@/components/Flag.vue";
import loadingImg from "@/assets/images/loading.gif";

export default {
  data: function () {
    return {
      paesi: [],
      title: "World",
      paese: "",
      stats: {},
      loading: false,
      chart: null,
      loadingImg,
      filters: {
        from: this.getInitialFromDate(),
        to: this.getInitialToDate(),
      },
    };
  },
  created: function () {
    this.$http.get("/ws/aree").then(
      (response) => {
        this.paesi = response.data;
      },
      (response) => {}
    );
  },
  computed: {},
  components: {
    Flag,
  },

  mounted() {
    try {
      let my = this;

      am4core.useTheme(am4themes_dark);
      am4core.useTheme(am4themes_animated);

      var chart = am4core.create("chartdiv", am4maps.MapChart);

      chart.projection = new am4maps.projections.Miller();

      var restoreContinents = function () {
        hideCountries();
        chart.goHome();
      };

      chart.zoomControl = new am4maps.ZoomControl();

      var homeButton = new am4core.Button();
      homeButton.events.on("hit", restoreContinents);

      homeButton.icon = new am4core.Sprite();
      homeButton.padding(7, 5, 7, 5);
      homeButton.width = 30;
      homeButton.icon.path =
        "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
      homeButton.marginBottom = 10;
      homeButton.parent = chart.zoomControl;
      homeButton.insertBefore(chart.zoomControl.plusButton);

      var hoverColorHex = "#9a7bca";
      var hoverColor = am4core.color(hoverColorHex);
      var hideCountries = function () {
        countryTemplate.hide();
      };

      var continentsSeries = chart.series.push(new am4maps.MapPolygonSeries());
      continentsSeries.geodata = am4geodata_continentsLow;
      continentsSeries.useGeodata = true;
      continentsSeries.exclude = ["antarctica"];

      var continentTemplate = continentsSeries.mapPolygons.template;
      continentTemplate.tooltipText = "{name}";
      continentTemplate.properties.fillOpacity = 0.8;
      continentTemplate.propertyFields.fill = "color";
      continentTemplate.nonScalingStroke = true;
      continentTemplate.events.on("hit", function (event) {
        my.handleMapObjectClick(event);
        if (!countriesSeries.visible) countriesSeries.visible = true;
        chart.zoomToMapObject(event.target);
        countryTemplate.show();
      });

      var contintentHover = continentTemplate.states.create("hover");
      contintentHover.properties.fill = hoverColor;
      contintentHover.properties.stroke = hoverColor;

      continentsSeries.dataFields.zoomLevel = "zoomLevel";
      continentsSeries.dataFields.zoomGeoPoint = "zoomGeoPoint";

      continentsSeries.data = [
        {
          id: "africa",
          color: "#7E7E7E",
        },
        {
          id: "asia",
          color: "#FFC64F",
          zoomLevel: 2,
          zoomGeoPoint: {
            latitude: 46,
            longitude: 89,
          },
        },
        {
          id: "oceania",
          color: "#74D039",
        },
        {
          id: "europe",
          color: "#00AAE5",
        },
        {
          id: "northAmerica",
          color: "#FF3C00",
        },
        {
          id: "southAmerica",
          color: "#FF8C00",
        },
      ];

      var countriesSeries = chart.series.push(new am4maps.MapPolygonSeries());
      var countries = countriesSeries.mapPolygons;
      countriesSeries.visible = false;
      countriesSeries.exclude = ["AQ"];
      countriesSeries.geodata = am4geodata_worldLow;
      countriesSeries.useGeodata = true;
      countriesSeries.events.once("inited", function () {
        hideCountries();
      });

      var countryTemplate = countries.template;
      countryTemplate.applyOnClones = true;
      countryTemplate.fill = am4core.color("#a791b4");
      countryTemplate.fillOpacity = 0.3;
      countryTemplate.strokeOpacity = 0.5;
      countryTemplate.nonScalingStroke = true;
      countryTemplate.tooltipText = "{name}";
      countryTemplate.events.on("hit", function (event) {
        my.handleMapObjectClick(event);
        chart.zoomToMapObject(event.target);
      });

      var countryHover = countryTemplate.states.create("hover");
      countryHover.properties.fill = hoverColor;
      countryHover.properties.fillOpacity = 0.8;
      countryHover.properties.stroke = hoverColor;
      countryHover.properties.strokeOpacity = 1;

      var ukSeries = chart.series.push(new am4maps.MapPolygonSeries());
      var ukCountries = ukSeries.mapPolygons;
      ukSeries.visible = false;
      ukSeries.geodata = am4geodata_ukCountriesLow;
      ukSeries.useGeodata = true;

      var ukTemplate = ukCountries.template;
      ukTemplate.applyOnClones = true;
      ukTemplate.fill = am4core.color("#a791b4");
      ukTemplate.fillOpacity = 0.3;
      ukTemplate.strokeOpacity = 0.5;
      ukTemplate.nonScalingStroke = true;
      ukTemplate.tooltipText = "{name}";
      ukTemplate.events.on("hit", function (event) {
        my.handleMapObjectClick(event);
        chart.zoomToMapObject(event.target);
      });

      var ukHover = ukTemplate.states.create("hover");
      ukHover.properties.fill = hoverColor;
      ukHover.properties.fillOpacity = 0.8;
      ukHover.properties.stroke = hoverColor;
      ukHover.properties.strokeOpacity = 1;

      this.chart = chart;
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleMapObjectClick(event) {
      this.title = event.target.dataItem.dataContext.name;
      this.paese = event.target.dataItem.dataContext.id;
      var id = this.getId(this.paese);
      this.loadStats(id);
    },

    loadStats(id) {
      this.loading = true;
      this.$http
        .get("/scouting/statistiche/" + id, { params: this.filters })
        .then(
          (response) => {
            this.stats = response.data;
            this.loading = false;
          },
          (response) => {}
        );
    },
    getId(paese) {
      if (paese.length == 2) {
        for (var i in this.paesi) {
          var item = this.paesi[i];
          if (item.sigla == paese) {
            return item.id;
          }
        }
      } else {
        switch (paese) {
          case "africa":
            return 250;
          case "asia":
            return 267;
          case "oceania":
            return 259;
          case "europe":
            return 253;
          case "northAmerica":
            return 255;
          case "southAmerica":
            return 262;
          case "GB-ENG":
            for (var i in this.paesi) {
              var item = this.paesi[i];
              if (item.sigla == "EN") {
                return item.id;
              }
            }
          case "GB-WLS":
            for (var i in this.paesi) {
              var item = this.paesi[i];
              if (item.sigla == "WA") {
                return item.id;
              }
            }
          case "GB-NIR":
            for (var i in this.paesi) {
              var item = this.paesi[i];
              if (item.sigla == "XN") {
                return item.id;
              }
            }
          case "GB-SCT":
            for (var i in this.paesi) {
              var item = this.paesi[i];
              if (item.sigla == "XS") {
                return item.id;
              }
            }
        }
      }
    },

    getInitialFromDate() {
      return moment("2021-07-01").format("YYYY-MM-DD");
    },

    getInitialToDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>
