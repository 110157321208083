var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"content"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"2"}},[_c('b-input-group',{attrs:{"prepend":"From"}},[_c('b-form-datepicker',{attrs:{"dark":true,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-input-group',{attrs:{"prepend":"To"}},[_c('b-form-datepicker',{attrs:{"dark":true,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1)],1),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-3",staticStyle:{"height":"70vh","overflow":"auto"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1 mr-2"},[_c('flag',{attrs:{"country":_vm.paese,"size":32}})],1),_c('div',{staticClass:"col"},[_c('span',{staticClass:"m-1",staticStyle:{"font-size":"1.25rem"}},[_vm._v(_vm._s(_vm.title))])])]),(_vm.loading)?_c('div',{staticStyle:{"width":"100%"},attrs:{"align":"center"}},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":_vm.loadingImg}})]):_vm._e(),(_vm.stats.squadre && !_vm.loading)?_c('div',{staticClass:"row my-2 border-bottom"},[_c('h5',[_vm._v("Squadre osservate: "+_vm._s(_vm.stats.squadre.length))])]):_vm._e(),_vm._l((_vm.stats.squadre),function(squadra){return _c('div',{key:squadra.id,staticClass:"row my-3 pb-2 align-items-center border-bottom"},[_c('div',{staticClass:"col-2"},[_c('img',{attrs:{"src":squadra.logo_url,"height":"50"}})]),_c('div',{staticClass:"col"},[_c('span',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(squadra.name))]),_c('br'),_c('span',[_vm._v("partite: "+_vm._s(squadra.partite_count))]),_c('br'),_c('span',[_vm._v("valutazioni: "+_vm._s(squadra.count_val_gio))])])])})],2),_c('div',{staticClass:"col-9"},[_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"chartdiv"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }